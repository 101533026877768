import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import './styles.css';
import Navbar from './Navbar'; // Import the Navbar component
// import heroImage from './demo_page_clean.svg'; // Update this path
import featureImage1 from './logo.svg'; // Update this path
import featureImage2 from './logo.svg'; // Update this path
import Footer from './Footer'; // Import the Footer component
import demoPdf from './demo_pdf.pdf'; // Import your PDF file
import demoImage from './demo_png.png'; // Import your PDF file



const LandingPage = () => {
  const [isPdfVisible, setPdfVisible] = useState(false); // State to manage PDF visibility
  const pdfRef = useRef(null); // Ref for the PDF viewer

  const [isImageVisible, setImageVisible] = useState(false); // State to manage image visibility

  const toggleImage = () => {
    setImageVisible(!isImageVisible);
  };

  const handleClickOutside = (e) => {
    if (e.target.className === 'image-overlay') {
      setImageVisible(false);
    }
  };



  // const [formData, setFormData] = useState({
  //   fullName: '',
  //   company: '',
  //   email: '',
  // });
  const togglePdf = () => {
    console.log("toggle done")
    setPdfVisible(!isPdfVisible);
  };

  // const handleClickOutside = (e) => {
  //   if (e.target.className === 'pdf-overlay') {
  //     setPdfVisible(false);
  //   }
  // };

  // Add event listener for clicks outside the PDF viewer
  React.useEffect(() => {
    if (isPdfVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPdfVisible]);


  const formRef = useRef(null); // Create a ref for the form section

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div className="landing-page">
      <Helmet>
        <title>Save money, boost retention with smart hiring</title> {/* Set the title */}
        <meta name="description" content="Identify potential red flags for your next candidates interview and estimate how long a candidate is likely to stay with your company." /> {/* Set the meta description */}
      </Helmet>
      <Navbar /> {/* Add the Navbar component here */}
      <div className="content">
      <section className="hero">
        <div className="hero-content">
          <h1>Save money, boost retention with smart hiring</h1>
          <p className="subtitle">In an era of high turnover, avoid costly hiring mistakes through data-driven candidate analysis</p>
          <button className="cta-button" onClick={scrollToForm}>Join the Waitlist</button>
        </div>
        <div className="hero-image">
            <img src="/demo_page_clean3.svg" alt="Lucid diagram" style={{width: '100%', height: 'auto'}} />
            <button className="view-demo-btn" onClick={toggleImage}>View Demo</button>
        </div>
      </section>

              {/* PDF Viewer */}
      {/* {isPdfVisible && (
        <div className="pdf-overlay" onClick={handleClickOutside}>
          <div className="pdf-popup">
            <button className="close-btn" onClick={togglePdf}>✖</button>
            <iframe src={demoPdf} title="Demo PDF" width="100%" height="600px" />
          </div>
        </div>
      )}
 */}

        {isImageVisible && (
          <div className="image-overlay" onClick={handleClickOutside}>
            <div className="image-popup">
              <button className="close-btn" onClick={toggleImage}>✖</button>
              <img src={demoImage} alt="Demo" width="100%" />
            </div>
          </div>
        )}

        
      <section className="features">
        <div className="feature">
          <h3>Identify Red Flags</h3>
          <img src={featureImage1} alt="Feature 1" className="feature-image" />
          <p>Spot potential issues in a candidate's history to ask targeted interview questions</p>
        </div>
        <div className="feature">
          <h3>Predict Employee Retention</h3>
          <img src={featureImage2} alt="Feature 2" className="feature-image" />
          <p>Estimate how long a candidate is likely to stay with your company</p>
        </div>
      </section>

        <div className="klaviyo-form-TxyeJA" ref={formRef}></div>

      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;