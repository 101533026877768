import React, { useState } from 'react';
// import './LandingPage2.css';
import Navbar from './Navbar'; // Import the Navbar component
// import heroImage from './demo_page_clean.svg'; // Update this path
import featureImage1 from './logo.svg'; // Update this path
import featureImage2 from './logo.svg'; // Update this path


const LandingPage2 = () => {
  const [isDemoOpen, setIsDemoOpen] = useState(false);

  return (
    <div className="landing-page">
      <Navbar /> {/* Add the Navbar component here */}
      <div className="content">
      <section className="hero">
        <div className="hero-content">
          <h1 className="main-heading">Save money, boost retention with smart hiring</h1>
          <p className="subtitle">In an era of high turnover, avoid costly hiring mistakes through data-driven candidate analysis</p>
          <div className="pricing-and-cta">
            <div className="pricing-section">
              <table className="pricing-table">
                <thead>
                  <tr>
                    <th>Credits</th>
                    <th>Price</th>
                    <th>Savings</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1 Credit</td>
                    <td>£100</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>5 Credits</td>
                    <td>£450</td>
                    <td>Save £50</td>
                  </tr>
                  <tr>
                    <td>10 Credits</td>
                    <td>£800</td>
                    <td>Save £200</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cta-section">
              <button className="cta-button">Order Analysis</button>
            </div>
          </div>
          <p className="linkedin-info">Simply provide a LinkedIn profile and let our AI do the magic</p>
        </div>
        <div className="hero-image">
            <img src="/demo_page_clean.svg" alt="Lucid diagram" style={{width: '100%', height: 'auto'}} />
            <button className="view-demo-btn" onClick={() => setIsDemoOpen(true)}>View Demo</button>
        </div>
      </section>
        
        <section className="features">
        <div className="feature">
          <h3>Identify Red Flags</h3>
          <img src={featureImage1} alt="Feature 1" className="feature-image" />
          <p>Spot potential issues in a candidate's history to ask targeted interview questions</p>
        </div>
        <div className="feature">
          <h3>Predict Employee Retention</h3>
          <img src={featureImage2} alt="Feature 2" className="feature-image" />
          <p>Estimate how long a candidate is likely to stay with your company</p>
        </div>
        </section>


        {/* <section className="pricing">
          <h2>How Our Pricing Works</h2>
          <p>To use our platform, you first purchase credits. Each credit allows you to generate one detailed report on a prospective employee.</p>
          <p>We offer three credit packages:</p>
          <ul>
            <li>1 Credit: £100 (1 report)</li>
            <li>5 Credits: £450 (save £50)</li>
            <li>10 Credits: £800 (save £200)</li>
          </ul>
        </section> */}

        {/* <section className="signup">
          <h2>Sign up today for early access</h2>
          <p>Join the hiring revolution</p>
          <form className="signup-form">
            <input type="text" placeholder="First Name" />
            <input type="text" placeholder="Last Name" />
            <input type="email" placeholder="Email" />
            <button type="submit">Submit</button>
          </form>
        </section> */}
      </div>
      {isDemoOpen && (
        <div className="demo-overlay">
          <div className="demo-content">
            <button className="close-demo-btn" onClick={() => setIsDemoOpen(false)}>&times;</button>
            <h2>Demo Content</h2>
            <p>This is where your demo content would go.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage2;