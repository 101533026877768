import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import LandingPage2 from './LandingPage2';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-GC0KK4SKRM', {
      page_path: location.pathname,
    });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/landing2" element={<LandingPage2 />} />
      </Routes>
    </Router>
  );
}

export default App;