import React from 'react';
import './Navbar.css';
import logo from './logo.svg'; // Update this path

function Navbar() {
  return (
    <nav className="navbar">
      <img src={logo} alt="Logo" className="navbar-logo" />
      <span className="navbar-title">TalentVerifAI</span>
    </nav>
  );
}

export default Navbar;
