import React from 'react';
import './Footer.css'; // Import the CSS for the footer

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>TalentVerifAI</p>
        <p>Email: info@talentverifai.com</p>
        <p>
          Address:<br />
          80A Ruskin Ave,<br />
          Welling, London DA16 3QQ
        </p>
      </div>
    </footer>
  );
};

export default Footer;
